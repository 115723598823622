<template>
  <div class="v-swiper-box" :style="{ height: propOpthion.imgH }">
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide v-for="(item, index) in option.list" :key="index">
        <template v-if="item">
          <slot name="swiper-slide" :slideData="item">
            <div class="img-box" :style="{ height: propOpthion.imgH, width: propOpthion.imgW }" :class="{ 'swiper-active': currentActive === index }" @click.stop="cb(item, index)">
              <img :src="item.split && item.split(',')[0]" @error="imgImg" :style="{ height: propOpthion.imgH }" />
            </div>
          </slot>
        </template>
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev" v-if="isShowSwiperBtn"></div>
      <div class="swiper-button-next" slot="button-next" v-if="isShowSwiperBtn"></div>
    </swiper>
  </div>
</template>
<script>
/**
 * option:
 * imgW:图片宽
 * imgH:图片高
 * slidesPerView:每页显示图片数，默认 3
 */
/* eslint-disable */
// TODO: change to ts lang
import def from '@/assets/def.jpg'
import iconVideo from '@/assets/icon_vedio.png'
// Swiper 5
import SwiperClass, { SwiperOptions } from 'swiper'
// vue-awesome-swiper 4.0
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  props: {
    option: {
      type: [Object, String],
      default: () => '',
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      iconVideo,
      currentActive: 0,
      swiperOption: {
        iconVideo,
        roundLengths: true,
        updateOnImagesReady: true,
        slidesPerView: this.option.slidesPerView ? this.option.slidesPerView : 3,
        // 图片间距
        spaceBetween: this.option.spaceBetween ? this.option.spaceBetween : 15,
        freeMode: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        direction: this.option.direction ? 'vertical' : 'horizontal',
        //swiper 高度
        height: this.option.height ? this.option.height : 'auto',
      },
      propOpthion: this.option ? this.option : { imgW: '', imgH: '' },
    }
  },
  computed: {
    isShowSwiperBtn() {
      let isT = false
      if (this.option.list) {
        isT = this.option.list.length > this.swiperOption.slidesPerView
      }
      return isT
    },
  },
  methods: {
    cb(url, index) {
      this.currentActive = index
      this.$emit('callback', url)
      return true
    },
    imgImg(e) {
      e.target.src = def
    },
  },
}
</script>
<style lang="stylus" scoped>


.swiper-button-prev:after, .swiper-button-next:after
  font-size 30px
  line-height 1.5

.swiper-button-prev, .swiper-button-next
  color $color-main

.img-box img
  object-fit contain
  background rgba(0,0,0,.1)
  cursor pointer

.swiper-active
  position relative

  &:before
    content ''
    position absolute
    left 0
    top 0
    width 100%
    height 100%
    background-color rgba(0,0,0,.25)
    cursor pointer
</style>
