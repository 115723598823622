




































import { Component, Vue, Prop } from 'vue-property-decorator'
import { listCallOaGetRela  } from '@/api'

interface IProduct {
  id: string
  materialId:string
  invqty: number
  partnumber:string
  canqty: number
  modeltype:string
}

@Component({
  components: {},
})
export default class HomeProducts extends Vue {
  @Prop({ default: 12 }) readonly num!: number
  @Prop() readonly materialId!: string
  products: Array<IProduct> = []

  mounted(): void {
    this.listCallOaGetRela()
  }

  fetchRecoProducts(): void {
    for (let i = 1; i < this.num + 1; i++) {
      this.products.push({
        id: i.toString(),
        partnumber: 'ADC12D1600CIUT',
        materialId: '12032',
        modeltype: '',
        invqty: 1,
        canqty: 1,
      })
    }

    // getRecoProducts(this.num).then((res) => {
    //   //  this.products = res.data
    //   // this.products = this.products
    // })
  }
   listCallOaGetRela(): void{
  
    listCallOaGetRela(this.materialId).then(res => {
      // console.log(res)
      // this.loading = false;
      if(res.status == 200){
          if(res.data.code == 200){
            
          //  console.log(res.data.data)
           this.products = res.data.data;
          //  console.log(this.productDetail)
          //  if(res.data.data.rsp.length == 0){
          //    this.$router.replace({ name: 'Home' })
          //  }
          }else{
            this.$message.error(res.data.msg );
          }
        }else{
          this.$message.error(res.data.statusText);
        } 
      }).catch(() => {
        console.log(' error ')
      })
  };
}
