





































































































































import $ from 'jquery'
import { Component, Vue, Watch } from 'vue-property-decorator'
import RecoProducts from '@/components/RecoProducts.vue'
import PreviewSwiper from '@/components/PreviewSwiper.vue'
import {   callOaGetMateriaDetail, addShoppingCart ,checkCustomerStatus} from '@/api'

import _defaultImg from '@/assets/cp.jpg'

interface IProduct {
  id: string
  images: Array<string>
  partnumber:string
  mftId:string
  
}

interface ISwiperOption {
  imgW: string
  imgH: string
  slidesPerView: number
  list: Array<string>
}

@Component({
  components: {
    RecoProducts,
    PreviewSwiper,
  },
})
export default class ProductDetail extends Vue {
  salerQq = window.localStorage.getItem("salerQq");
  defaultImg = _defaultImg

  firstImg = _defaultImg
  isAmountValidate = false
  isQtyValidate = false
  isMinAmountValidate = false
  isSubmit = false

  swiperOption: ISwiperOption = {
    imgW: '68px',
    imgH: '68px',
    slidesPerView: 4,
    list: [],
  }
  productDetail = {
    id: "1",
    materialId:'0',
    images: [_defaultImg],
    partnumber:"",
    leadtime:"",
    qty:0,
    description:"",
    mft:"",
    stockstatus:[],
    rsp:[],
    mftId:"",
    pc:"",
    goodsUuid:"",
    stock:[]
  }

  orderAmount = 1 ;
  limitMinBuyCount = 1;
  loading = true;

  @Watch('$route.params.id')
  onIdChanged(): void {
    $('body,html').animate({ scrollTop: 0 }, 400)
    // this.callOaGetMateriaDetail()
  }

  mounted(): void {
    $('body,html').animate({ scrollTop: 0 }, 400)
    this.callOaGetMateriaDetail()
    //this.fetchProductDetail()
  }

  fakePrepare(): void {
    const id = parseInt(this.$route.params.id)
    this.productDetail.images = [_defaultImg]
    if (id === 2) {
      this.productDetail.images = []
    }

    if (id === 1) {
      this.productDetail.images = ['https://ef-base-common-img.oss-cn-beijing.aliyuncs.com/ef-img/6_16036008500554', 'https://ef-base-common-img.oss-cn-beijing.aliyuncs.com/ef-img/6_16036007902801', 'https://ef-base-common-img.oss-cn-beijing.aliyuncs.com/ef-img/8_16036007902962', 'https://ef-base-common-img.oss-cn-beijing.aliyuncs.com/ef-img/9_16036007903105', 'https://ef-base-common-img.oss-cn-beijing.aliyuncs.com/ef-img/8_16036007903249', 'https://ef-base-common-img.oss-cn-beijing.aliyuncs.com/ef-img/5_16036007903397', 'https://ef-base-common-img.oss-cn-beijing.aliyuncs.com/ef-img/2_16036007903471']
      this.firstImg = this.productDetail.images[0]
      this.swiperOption.list = this.productDetail.images
    }
  }

  fetchProductDetail(): void {
    this.fakePrepare()
    // getProductById(parseInt(this.$route.params.id)).then((res) => {
    //   // this.productDetail = res.data
    // })
  }

  changeUrl(url: string): void {
    this.firstImg = url
  }
 
  callOaGetMateriaDetail(): void{
  
    callOaGetMateriaDetail(this.$route.params.id).then(res => {
      // console.log(res)
      this.loading = false;
      if(res.status == 200){
          if(res.data.code == 200){
            
          //  console.log(res.data.data)
           this.productDetail = res.data.data;
           
          //  console.log(this.productDetail)
           if(res.data.data.rsp.length == 0){
             this.$router.replace({ name: 'Home' })
           }else{
             let len = res.data.data.rsp.length;
             this.orderAmount = res.data.data.rsp[len -1].qty;
             this.limitMinBuyCount = this.orderAmount;
           }
           if(res.data.data.images.length != 0){
              this.firstImg = this.productDetail.images[0]
              this.swiperOption.list = this.productDetail.images
           }
           
          }else{
            this.$message.error(res.data.msg );
          }
        }else{
          this.$message.error(res.data.statusText);
        } 
      }).catch(() => {
        console.log(' error ')
      })
  };

 //新增
  addShoppingCart(): void {
    // console.log(this.orderAmount)
    // if(this.orderAmount < this.limitMinBuyCount){
    //   this.$message.warning("该商品最小购买量为"+this.limitMinBuyCount);
    //   return;
    // }
    let userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    console.log(this.productDetail.id)
    let data = {
      partnumber: this.productDetail.partnumber,
      qty: this.productDetail.qty,
      mft: this.productDetail.mft,
      rsp: this.productDetail.rsp,
      stockstatus : this.productDetail.stockstatus,
      amount : this.orderAmount,  
      isValid:1,
      materialId:this.productDetail.materialId,
      leadtime:this.productDetail.leadtime,
      mftId:this.productDetail.mftId,
      pc:this.productDetail.pc,
      // goodsUuid:this.productDetail.materialId+"_"+this.productDetail.pc
      goodsUuid:this.productDetail.id
    };
    addShoppingCart(data)
      .then((res) => {
        // console.log(res)
        if (res.status == 200) {
          if (res.data.code == 200) {
            // console.log(res)
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
            // this.$message.success("添加成功");
            // this.dialogFormVisible = false;
            // this.listUserAddress();
            this.open()
          } else if(res.data.code == 401) {
            console.log(res.data.msg)
          }else {
            this.$message.warning(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        this.$message.error("服务器出了点小问题,工程师正在玩命抢修中");
      });
  }
  open() :void{
        this.$confirm('商品已经成功加入购物车!', '', {
          confirmButtonText: '前往购物车',
          cancelButtonText: '我再逛逛',
          type: 'success'
        }).then(() => {
          this.$router.push({ name: 'Cart' });
        }).catch(() => {
         console.log("取消")
        });
      }

  confirmOrder():void {
    // if(this.orderAmount < this.limitMinBuyCount){
    //   this.$message.warning("该商品最小购买量为"+this.limitMinBuyCount);
    //   return;
    // }
    let checkedProducts = [this.productDetail.id];
    console.log(checkedProducts)
    console.log(this.productDetail)
    // this.$router.push('/confirm-order')
    if(this.isQtyValidate || this.isMinAmountValidate){
      return ;
    }

     checkCustomerStatus()
      .then((res) => {
        // console.log(res)
        if (res.status == 200) {
          if (res.data.code == 200) {
            this.$router.push({ path: '/confirm-order', query: { checkedProducts : `${checkedProducts}` ,from : "detail",amount : `${this.orderAmount}`}});
          } else if(res.data.code == 401) {
            console.log(res.data.msg)
          }else {
            this.$message.warning(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        this.$message.error("服务器出了点小问题,工程师正在玩命抢修中");
      });
  }



  goCart(): void {
    this.validateAmount()
    this.isSubmit = true
    if (this.isAmountValidate &&  !this.isQtyValidate && !this.isMinAmountValidate) {
      // this.$router.push('/cart')
      this.addShoppingCart();
    }
  }

  validateAmount():void {
    this.isSubmit = true
    this.isAmountValidate = /^\d+$/.test(this.orderAmount.toString()) && parseInt(this.orderAmount.toString()) > 0
    if(this.isAmountValidate){
        this.isQtyValidate = parseInt(this.orderAmount.toString()) > parseInt(this.productDetail.qty.toString());
        this.isMinAmountValidate = parseInt(this.orderAmount.toString()) < parseInt(this.limitMinBuyCount.toString());
    }else{
      this.isQtyValidate = false;
      this.isMinAmountValidate = false;

    }
  }
}
